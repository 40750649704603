<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedItem !== null"
      :show.sync="confirmDialog"
      :text="confirmDeleteText"
      @confirm-delete="reallyDeleteItem"
    />
    <BarcodePrintDialog
      v-if="modalItem !== null || foundInstances.length > 1"
      :show="barcodeModal"
      :barcode-count.sync="barcodeCount"
      :print-type.sync="printType"
      :title="$t('tasks.stockLoading.rightProductWrongInstanceScanned')"
      :text="foundInstances.length > 1 ? $t('tasks.stockLoading.chooseInstanceAndHowMuch')
        : $t('tasks.stockLoading.chooseHowMuch', [modalItem.instanceLabel])"
      :confirm-text="$t('products.instances.barcodes.printMany')"
      :disabled="modalItem === null"
      @update:show="handleModalUpdate"
      @print-barcode="reallyPrintBarcode"
    >
      <v-col
        v-if="foundInstances.length > 1"
        xs="6"
      >
        <v-select
          v-model="modalItem"
          return-object
          prepend-icon="$productInstance"
          :rules="[formRules.required]"
          :items="foundInstances"
          item-text="instanceLabel"
          item-value="instanceId"
        />
      </v-col>
    </BarcodePrintDialog>
    <Alert
      :show-alert="showMovingAllAlert"
      :display-text="$t('tasks.itemsCard.moveEverythingInProgress')"
    />
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetActiveLocationId"
    />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-flex
        v-for="card of cardTypes"
        :key="card.type"
        xs12
        :class="'md' + 12 / cardTypes.length "
      >
        <TaskItemsCard
          :active-location-id="activeLocationId"
          :api="API"
          :card-type="card.type"
          :items="card.items"
          items-update-emit="fetch-items"
          :prices="prices"
          :task-info="taskInfo"
          task-lang-path="stockLoading."
          :highlight-single-location="highlightSingleLocation(card.type)"
          :inventory-empty="itemsInInventory.length === 0"
          :is-destination-scanned="isDestinationScanned"
          @moveAll="moveAllFromInventory"
          @stock-loading-item-delete="handleItemDelete"
          @return-item="onReturnItem"
        >
          <template #items-location-header="{ locationId }">
            <TaskItemsLocationHeaderWithAllowedItems
              :location-id="locationId"
              :allowed-instances="allowedInstances[locationId]"
              :allowed-locations-loaded="allowedLocationsLoaded"
              :card-type="card.type"
              :num-of-instances="items.length"
            />
          </template>
        </TaskItemsCard>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
    import {CodeType} from "@/enum/code_type";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {scrollTo} from "@/service/Vuetify";
    import {has} from "@/utils/object";
    import {TaskStockLoadingAPI as API} from "@/api/TaskStockLoadingAPI";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import TaskItemsCard from "@/app/tasks/components/taskItemsCard/TaskItemsCard.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {scannerModes} from "@/enum/scanner_mode";
    import {readerFeedback} from "@/utils/readerFeedback";
    import {EventBus} from "@/service/EventBus";
    import BarcodePrintDialog from "@/app/components/BarcodePrintDialog.component";
    import {PrintType} from "@/enum/print_type";
    import {ProductAPI} from "@/api/ProductAPI";
    import * as Export from "@/service/Export";
    import {MoveAllMixin} from "@/app/mixins/MoveAllMixin";
    import Alert from "@/app/components/Alert.component";
    import formRules from "@/utils/formRules";
    import {Store} from "@/service/store/Store";
    import {UpdateQuantityMixin} from "@/app/mixins/UpdateQuantityMixin";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {TaskMovementType} from "@/enum/task_movement_type";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import TaskItemsLocationHeaderWithAllowedItems
        from "@/app/tasks/components/taskItemsCard/TaskItemsLocationHeaderWithAllowedItems.component.vue";

    export default {
        name: "StockLoadingLoading",
        components: {
            TaskItemsLocationHeaderWithAllowedItems,
            TaskConflict, TaskBarcodeReader, TaskItemsCard, BarcodePrintDialog, Alert, ConfirmDeleteDialog
        },
        mixins: [TaskStateMixin, TaskAssignMixin, TaskFetchItemsMixin, EventsListenerMixin, ACLMixin, MoveAllMixin, UpdateQuantityMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            prices: {},
            activeLocationId: null,
            API: API,
            triggerCards: 0,
            TaskItemsCardType: TaskItemsCardType,
            bottomSheetVisible: false,
            instanceBarcodes: {},
            modalItem: null,
            barcodeModal: false,
            foundInstances: [],
            barcodeCount: 1,
            printType: PrintType.PDF,
            showMovingAllAlert: false,
            formRules: formRules,
            chosenInstanceId: null,
            deletedItem: null,
            confirmDialog: false,
            conflict: null
        }),
        computed: {
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'update-quantity': this.updateQuantity,
                    'update-price': this.updateBuyPrice,
                    'bottom-sheet-visible': state => this.bottomSheetVisible = state,
                    'task-movement-conflict': cnflct => this.conflict = cnflct,
                    ...this.taskFetchItemsEvents
                };
            },
            itemsToMove: function () {
                return this.items.filter(el => el.quantity_to_move - (el.quantity_in_user_inventory + el.processed_quantity) > 0);
            },
            itemsInInventory: function () {
                return this.items.filter(el => el.quantity_in_user_inventory !== 0);
            },
            itemsMoved: function () {
                return this.items.filter(el => el.processed_quantity !== 0);
            },
            cardTypes: function () {
                this.triggerCards;
                return [{
                    type: TaskItemsCardType.TO_MOVE,
                    items: this.itemsToMove
                }, {
                    type: TaskItemsCardType.IN_INVENTORY,
                    items: this.itemsInInventory
                }, {
                    type: TaskItemsCardType.MOVED,
                    items: this.itemsMoved
                }];
            },
            scannerMode: function () {
                if (this.activeLocationId !== null) {
                    return scannerModes.DESTINATION;
                }
                return scannerModes.IDLE_LOCATION_OR_ITEM;
            },
            isDestinationScanned: function () {
                return this.scannerMode === scannerModes.DESTINATION;
            },
            highlightSingleLocation: function () {
                return cardType => {
                    switch (cardType) {
                    case TaskItemsCardType.IN_INVENTORY:
                        return !this.activeLocationId
                            || (this.activeLocationId && this.itemsInInventory.length > 0);
                    case TaskItemsCardType.TO_MOVE:
                        return this.itemsToMove.length > 0 && (
                            (this.scannerMode === scannerModes.IDLE_LOCATION_OR_ITEM)
                            || this.activeLocationId && this.itemsInInventory.length === 0
                        );
                    default:
                        return false;
                    }
                };
            },
            allowedLocationsLoaded: function () {
                return !this.items.filter(item => item.allowedLocationIds === undefined).length;
            },
            confirmDeleteText: function () {
                if (!this.deletedItem) {
                    return null;
                }
                let removeFromLabel = this.$t('tasks.stockLoading.confirmDelete.fromInventory');
                const locationId = this.deletedItem.locationId;
                if (locationId) {
                    removeFromLabel = this.$t('tasks.stockLoading.confirmDelete.fromDestination');
                    const locationLabel = this.$options.filters.locationLabel(this.LocationCache[locationId]);
                    removeFromLabel += (' ' + locationLabel);
                }
                const itemInstance = this.deletedItem.item.instance;
                return this.$options.filters.instanceLabel(itemInstance) + ', '
                    + this.deletedItem.deletedCount + this.$options.filters.productMeasureLabel(itemInstance.product) + ', '
                    + removeFromLabel;
            }
        },
        watch: {
            'taskInfo.tab': function () {
                this.fetchBuyPrices();
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.instanceBarcodes = {};
            this.fetchItems({initial: lifeCycleHook === this.LifeCycleHook.CREATED})
                .then(() => {
                    this.fetchBuyPrices();
                    if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                        this.ready = true;
                    }
                }).catch(this.snack);
            this.conflict = null;
        },
        methods: {
            fetchBuyPrices: function () {
                if (this.isChief) {
                    return this.API.getAllBuyPrices(this.taskInfo.taskId)
                        .then(response => {
                            const instancePrices = {};
                            response.data.forEach(price => {
                                instancePrices[price.product_instance_id] = {
                                    priceId: price.id,
                                    price: price.buy_price,
                                    vat: Number.parseFloat(price.vat)
                                };
                            });
                            this.items.forEach(item => {
                                item.priceId = instancePrices[item.product_instance_id].priceId;
                                this.$set(this.prices, item.id, instancePrices[item.product_instance_id]);
                            });
                        })
                        .catch(this.snack);

                } else {
                    return Promise.resolve();
                }
            },
            updateBuyPrice: function (payload, callback) {
                this.API.updateBuyPrice(this.taskInfo.taskId, payload.itemId, payload.price, payload.vat)
                    .then(() => {
                        this.fetchBuyPrices();
                        callback();
                    })
                    .catch(this.snack);
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    this.tryToMove(barcodeInfo.object_id, barcodeInfo.quantity * quantity);
                    const item = this.items.find(item => item.product_instance_id === barcodeInfo.object_id);
                    if (item) {
                        // fetch allowed locations for existing items if not fetched already
                        this.fetchInstanceAllowedLocations(item);
                    }
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    this.changeActiveLocation(barcodeInfo);
                    this.$nextTick(() => {
                        scrollTo('taskItemsCard-' + TaskItemsCardType.MOVED);
                    });
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                if (this.scannerMode === scannerModes.IDLE_LOCATION_OR_ITEM) {
                    this.readingFail('tasks.itemsCard.scanLocationOrItem');
                } else {
                    this.readingFail();
                }
            },
            tryToMove: function (instanceId, quantity) {
                let promiseCallback = () => Promise.resolve();
                const item = this.items.find(item => item.product_instance_id === instanceId);
                if (this.scannerMode === scannerModes.IDLE_LOCATION_OR_ITEM) {
                    // from source to inventory
                    if (item) {
                        if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                            const leftToMove = item.quantity_to_move - (item.quantity_in_user_inventory + item.processed_quantity);
                            if (quantity > leftToMove) {
                                this.advancedSnack({
                                    text: 'tasks.stockLoading.loadedLimit',
                                    params: [item.processed_quantity + item.quantity_in_user_inventory + quantity, item.quantity_to_move]
                                });
                                this.readingFail();
                                return;
                            }
                        }
                        promiseCallback = () => API.pickUpFromSource(this.taskInfo.taskId, item.id, null, quantity);
                    } else {
                        // item does not exist
                        if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                            // check whether it is right item, but bad instance (on source)
                            this.checkInstance(instanceId).then(found => {
                                if (!found) {
                                    this.readingFail('tasks.stockLoading.notToBeLoaded');
                                }
                            // else readingDone / readingFail handled by barcodeModal which is now open
                            }).catch(() => this.readingFail());
                            return;
                        } else {
                            promiseCallback = () => API.createItem(this.taskInfo.taskId, null, instanceId, quantity);
                        }
                    }
                } else {
                    // to destination
                    if (item) {
                        // item exists
                        if (this.itemsInInventory.find(itemInInv => itemInInv.id === item.id)) {
                            // item is in inventory
                            if (this.allowedInstances[this.activeLocationId]
                                && item.allowedLocationIds
                                && !this.allowedInstances[this.activeLocationId].find(instance => instance.id === item.instance.id)) {
                                this.readingFail('tasks.stockLoading.itemCanNotBePutOnLocation');
                                return;
                            }
                            if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                                const leftToMove = item.quantity_to_move - item.processed_quantity;
                                if (quantity > leftToMove) {
                                    this.advancedSnack({
                                        text: 'tasks.stockLoading.loadedLimit',
                                        params: [item.processed_quantity + quantity, item.quantity_to_move]
                                    });
                                    this.readingFail();
                                    return;
                                }
                            }
                            promiseCallback = () => API.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                        } else {
                            // item is not in inventory
                            if (this.itemsInInventory.length) {
                                // inventory is not empty, do not move item
                                this.readingFail('tasks.stockLoading.onlyItemsFromInventory');
                                EventBus.$emit('bad-quantity-entered', item.id);
                                return;
                            }
                            if (this.allowedInstances[this.activeLocationId]
                                && item.allowedLocationIds
                                && !this.allowedInstances[this.activeLocationId].find(instance => instance.id === item.instance.id)) {
                                this.readingFail('tasks.stockLoading.itemCanNotBePutOnLocation');
                                return;
                            }
                            if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                                //quantity_in_user_inventory is 0
                                const leftToMove = item.quantity_to_move - item.processed_quantity;
                                if (quantity > leftToMove) {
                                    this.advancedSnack({
                                        text: 'tasks.stockLoading.loadedLimit',
                                        params: [item.processed_quantity + quantity, item.quantity_to_move]
                                    });
                                    this.readingFail();
                                    return;
                                }
                            }
                            if (quantity !== 0) {
                                promiseCallback = () => API.updateItem(this.taskInfo.taskId, item.id, this.activeLocationId, quantity);
                            }
                        }
                    } else {
                        // item does not exist
                        // check whether it is right item, but bad instance
                        this.checkInstance(instanceId, true).then(found => {
                            if (!found) {
                                if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                                    this.readingFail('tasks.stockLoading.notToBeLoaded');
                                }
                            }
                        // else readingDone / readingFail handled by barcodeModal which is now open
                        }).catch(() => this.readingFail());
                        if (this.taskInfo.details.strict_mode !== TaskItemsStrictMode.FREE) {
                            return;
                        } else {
                            promiseCallback = () => API.createItem(this.taskInfo.taskId, this.activeLocationId, instanceId, quantity);
                        }
                    }
                }
                this.sendUpdatedQuantity().then(() => {
                    promiseCallback().then(() => {
                        this.conflict = null;
                        readerFeedback.success();
                        this.fetchItems({debounceQuantities: true, onlyInstanceId: instanceId}).then(() => {
                            const item = this.items.find(item => item.product_instance_id === instanceId);
                            let promise = Promise.resolve();
                            if (item) {
                                // fetch allowed locations for created items, callee won't fetch if locations are already fetched or in cache
                                promise = this.fetchInstanceAllowedLocations(item).then(() => {
                                    this.computeAllowedInstances();
                                });
                                this.fetchInstanceAlreadyPlacedAt(item, this.taskInfo.details.subordinate_stock.stock_id);
                            }
                            promise.then(() => {
                                this.triggerCards++;
                            }).finally(this.readingDoneNoVibrate);
                        }).catch(err => {
                            this.snack(err);
                            this.readingDoneNoVibrate();
                        });
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            this.conflict = err.response.data;
                            // send empty message to stop barcode loading
                            this.readingFail('');
                        } else if (Object.keys(err)[0] === 'quantity') {
                            this.readingFail('tasks.itemsCard.updateQuantity.notEnoughItemsInInventory');
                        } else {
                            this.readingFail(err);
                        }
                    });
                }).catch(() => this.readingFail());
            },
            checkInstance: function (instanceId, lookInInventoryFirst = false) {
                return ProductAPI.getDirectProductIdForInstance(instanceId).then(productId => {
                    if (lookInInventoryFirst) {
                        const foundInstance = this.itemsInInventory.find(item => item.instance.product.id === productId);
                        if (foundInstance) {
                            // item found in inventory, instance code has already been printed, storekeeper must look for it
                            this.readingFail('tasks.stockLoading.lookForAnotherCode');
                            return true;
                        }
                    }
                    this.foundInstances = [];
                    const allInstancesPromises = [];
                    // need to find all instances and let user pick which one to choose
                    const foundInstances = this.itemsToMove.filter(item => item.instance.product.id === productId);
                    for (const instance of foundInstances) {
                        const instanceId = instance.instance.id;
                        if (!has(this.instanceBarcodes, instanceId)) {
                            this.$set(this.instanceBarcodes, instanceId, []);
                        }
                        const promises = [];
                        if (this.instanceBarcodes[instanceId].length === 0) {
                            promises.push(
                                ProductAPI.getAllInstanceBarcodes(productId, instanceId)
                                    .then(response => {
                                        this.$set(this.instanceBarcodes, instanceId, response.data);
                                    }).catch(this.snack)
                            );
                        }
                        allInstancesPromises.push(...promises);
                        Promise.all(promises).then(() => {
                            if (this.instanceBarcodes[instanceId].length) {
                                this.foundInstances.push({
                                    productId: productId,
                                    instanceId: instanceId,
                                    instanceLabel: this.$options.filters.instanceLabel(instance.instance),
                                    code: this.instanceBarcodes[instanceId][0].code
                                });
                            } else {
                                this.readingFail('tasks.stockLoading.notToBeLoaded');
                            }
                        });
                    }
                    return Promise.all(allInstancesPromises).then(() => {
                        if (this.foundInstances.length) {
                            if (this.foundInstances.length === 1) {
                                this.modalItem = this.foundInstances[0];
                            } else {
                                if (Store.getters['userConfig/automagicallyChooseInstance']) {
                                    this.modalItem = this.foundInstances[0];
                                }
                            }
                            this.barcodeModal = true;
                            return true;
                        }
                        return false;
                    });
                });
            },
            resetActiveLocationId: function () {
                this.activeLocationId = null;
            },
            changeActiveLocation: function (barcodeLocation) {
                const locationId = barcodeLocation.object_id;
                if (!has(this.taskInfo.details.whitelist, locationId)) {
                    this.readingFail('tasks.stockLoading.locationNotAllowed');
                    return;
                }
                this.getAllowedInstances(locationId);
                this.activeLocationId = locationId;
                this.readingDone();
            },
            reallyPrintBarcode: function () {
                this.barcodeModal = false;
                const item = this.modalItem;
                ProductAPI.printInstanceBarcode(item.productId, item.instanceId, item.code, this.printType, this.barcodeCount)
                    .then(response => {
                        const fileExtension = this.printType;
                        this.modalItem = null;
                        Export.print(response.data.url, 'code_' + item.code + '_' + this.barcodeCount + '.' + fileExtension, this.printType);
                        this.barcodeCount = 1;
                    }).catch(this.snack)
                    .finally(() => {
                        this.readingDoneNoVibrate();
                    });
            },
            handleModalUpdate: function (newValue) {
                if (newValue === false) {
                    this.readingDoneNoVibrate();
                    this.modalItem = null;
                }
                this.barcodeModal = newValue;
            },
            handleItemDelete: function (itemId, locationId) {
                const item = this.items.find(item => item.id === itemId);
                if (item === undefined) {
                    return;
                }
                let toBeRemoved = item.quantity_in_user_inventory;
                if (locationId) {
                    const itemAtLocation = item.destination_locations.find(dstLoc => dstLoc.location_id === locationId);
                    toBeRemoved = itemAtLocation ? itemAtLocation.quantity : 0;
                }
                this.deletedItem = {
                    item: item,
                    locationId: locationId,
                    deletedCount: toBeRemoved
                };
                this.confirmDialog = true;
            },
            reallyDeleteItem: function () {
                this.confirmDialog = false;
                const {item, locationId, deletedCount} = this.deletedItem;
                let curQuantityOnLocation = item.quantity_to_move - item.processed_quantity - item.quantity_in_user_inventory;
                let movementType = TaskMovementType.FROM_INVENTORY_TO_SOURCE;
                if (!locationId) {
                    item.quantity_in_user_inventory = 0;
                } else {
                    const itemAtLocation = item.destination_locations.find(dstLoc => dstLoc.location_id === locationId);
                    const quantityAtLocation = itemAtLocation ? itemAtLocation.quantity : 0;
                    item.destination_locations = item.destination_locations.filter(destLoc => destLoc.location_id !== locationId);
                    item.processed_quantity = item.processed_quantity - quantityAtLocation;
                    curQuantityOnLocation = quantityAtLocation;
                    movementType = TaskMovementType.FROM_DESTINATION_TO_SOURCE;
                }

                const index = this.items.findIndex(it => it.id === item.id);
                // have to add movement otherwise quantities would not add up
                this.addItemMovement(item, locationId, curQuantityOnLocation, deletedCount, movementType);
                if (item.quantity_in_user_inventory === 0 && item.quantity_to_move === 0 && item.processed_quantity === 0) {
                    this.items.splice(index, 1);
                    this.computeAllowedInstances();
                    this.getAllowedInstances(this.activeLocationId);
                    this.triggerCards++;
                }
            // delete of item handled by UpdateQuantityMixin
            }
        }
    };
</script>

<style scoped>

</style>

<template>
  <div>
    <x-toolbar />
    <v-container fluid>
      <StockFilter
        :form.sync="form"
      />

      <x-data-table
        :headers="headers"
        :hidden-query-filters="hiddenFilters"
        :loading.sync="loading"
        :items.sync="items"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :reload="reload"
        :api-filter="apiFilter"
        :show-search-bar="false"
        @update:filter="onTableFilterChange"
      >
        <template #item.last_change="{ item }">
          <DateTimeWithTooltip
            :date-time="item.last_change"
          />
        </template>
        <template #item.loaded_at="{ item }">
          <DateTimeWithTooltip
            :date-time="item.loaded_at"
            abs-date-first
          />
        </template>
      </x-data-table>
    </v-container>
    <template v-if="isChief">
      <v-divider
        class="my-3"
      />
      <v-container
        fluid
      >
        <Alert
          :display-text="$t('stocks.stockStatus.buyPrices.newLocation')"
          show-alert
          :actions="[{
            text: $t('base.reports.redirect'),
            to: '/exports'
          }]"
        />
        <StockBuyPricesComponent
          v-for="(type, index) of ['IN', 'CURR', 'OUT']"
          :key="type"
          :type="type"
          :class="index > 0 ? 'mt-4' : ''"
        />
      </v-container>
    </template>
  </div>
</template>

<script>
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {has} from "@/utils/object";
    import {createHeaders, createHiddenFilters} from "@/utils/table";
    import {stockStatusHiddenFilters, stockStatusTable} from "@/app/overview/status/definitions/stockStatus.form";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import {APIFilters, APIFilterOP} from "@/service/APIFilters";
    import StockBuyPricesComponent from "@/app/overview/reports/components/StockBuyPrices.component";
    import Alert from "@/app/components/Alert.component";
    import StockFilter from "@/app/overview/stock/components/StockFilter.component.vue";
    import {StockFilterForm} from "@/app/overview/stock/definitions/stockFilter.form";
    import {ACLMixin} from "@/app/mixins/ACLMixin";

    export default {
        name: "StockStatus",
        components: {StockFilter, Alert, StockBuyPricesComponent, DateTimeWithTooltip},
        mixins: [ACLMixin],
        data: () => ({
            form: new StockFilterForm(),

            filter: {},
            apiFilter: [],
            items: [],
            headers: [],
            loading: false,
            reload: 0,
        }),
        computed: {
            actions: function () {
                if (this.isOrganizer) {
                    return [];
                }
                return [
                    {
                        loading: this.loading,
                        action: this.showItem,
                        icon: '$product',
                        label: 'stocks.stockStatus.showItem'
                    }, {
                        condition: item => !has(item.stock_location, 'user'),
                        loading: this.loading,
                        action: this.showLocation,
                        icon: '$location',
                        label: 'stocks.stockStatus.showLocation'
                    }
                ];
            },
            hiddenFilters: function () {
                return createHiddenFilters(stockStatusHiddenFilters);
            },
            apiDataSource: function () {
                return StockStatusAPI.getAll.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiDataSourceAllPages: function () {
                return StockStatusAPI.getAllPages.bind(StockStatusAPI, this.apiFilterParam);
            },
            apiFilterParam: function () {
                return this.apiFilter.length ? {filter: APIFilters.makeFilter(this.apiFilter)} : {};
            },
        },
        watch: {
            filter: {
                deep: true,
                handler: function () {
                    this.apiFilter = Object.values(this.filter).filter(filter => filter !== null);
                    this.reload++;
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.headers = createHeaders(stockStatusTable, 'stocks.stockStatus.table.', this.isChief);

            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                this.filter = {
                    quantity: {
                        [APIFilterOP.GREATER_THAN]: {
                            quantity: 0
                        }
                    }
                };
            }
        },
        methods: {
            onTableFilterChange: function (filters) {
                if (has(filters, 'stock.id')) {
                    this.form.stockId = filters['stock.id'];
                }
                if (has(filters, 'substock.id')) {
                    this.form.subStockId = filters['substock.id'];
                }
            },
            showItem: function (item) {
                this.$router.push('/products/' + item.product_instance.product.id);
            },
            showLocation: function (item) {
                this.$router.push('/stocks/' + item.stock_id + '/substocks/' + item.substock_id + '/locations/' + item.stock_location.id);
            },
        }
    };
    // TODO cache
    // TODO more actions
</script>

<style scoped>

</style>

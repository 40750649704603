import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";

const TaskAPI =  {

    API: BaseAPI,
    DOMAIN: 'tasks',
    DOMAIN_ATTACHMENTS: 'attachments',
    DOMAIN_NOTES: 'notes',
    DOMAIN_ATTRIBUTES: 'attributes',
    DOMAIN_TIMEENTRIES: 'time-entries',
    LANG_TIMEENTRIES: 'timeEntries',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     */
    getAll(...params) {
        return this.API.get(
            this.DOMAIN,
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllPages(...params) {
        return APIHelper.getAllPages(
            this.DOMAIN,
            this.DOMAIN,
            merge(...params)
        );
    },

    get(taskId) {
        return this.API.get([this.DOMAIN, taskId]);
    },

    getOneWithPolling(filter) {
        return APIHelper.polling(
            this.getAll.bind(this, {filter}),
            20, res => {
                return res.data.error || res.data.items && res.data.items.length === 1;
            }).promise;
    },



    // Attachments

    getAllAttachments(taskId) {
        return this.API.get([this.DOMAIN, taskId, this.DOMAIN_ATTACHMENTS]);
    },

    getAttachment(taskId, attachmentId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ATTACHMENTS, attachmentId],
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS]
        );
    },

    getUnassignedAttachment(attachmentId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS, attachmentId]
            // TODO handle return codes based on app needs
        );
    },

    createAttachment(file) {
        const data = new FormData();
        data.append('file', file);
        return this.API.post(
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS],
            data,
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS],
            'multipart/form-data');
    },

    assignAttachment(taskId, attachmentId) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ATTACHMENTS],
            {attachment_id: attachmentId},
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS]
            );
    },

    deleteAttachment(taskId, attachmentId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_ATTACHMENTS, attachmentId],
            [this.DOMAIN, this.DOMAIN_ATTACHMENTS]
        );
    },



    // Notes

    getAllNotes(taskId) {
        return this.API.get([this.DOMAIN, taskId, this.DOMAIN_NOTES]);
    },

    getNote(taskId, noteId) {
        return this.API.get([this.DOMAIN, taskId, this.DOMAIN_NOTES, noteId]);
    },

    createNote(taskId, text) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_NOTES],
            {text: text},
            [this.DOMAIN, this.DOMAIN_NOTES]
        );
    },

    updateNote(taskId, noteId, text) {
        return this.API.put(
            [this.DOMAIN, taskId, this.DOMAIN_NOTES, noteId],
            {text: text},
            [this.DOMAIN, this.DOMAIN_NOTES]
        );
    },

    deleteNote(taskId, noteId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_NOTES, noteId],
            [this.DOMAIN, this.DOMAIN_NOTES]
        );
    },


    // Task attribute values

    getAllTaskAttributes(taskId, ...params) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ATTRIBUTES],
            this.DOMAIN,
            merge(...params)
        );
    },

    getAllAttributes(...params) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            this.DOMAIN,
            merge(...params)
        );
    },

    /**
     * @deprecated Too heavy API usage, use pagination
     */
    getAllAttributesAllPages(...params) {
        return APIHelper.getAllPages(
            [this.DOMAIN, this.DOMAIN_ATTRIBUTES],
            this.DOMAIN,
            merge(...params)
        );
    },

    createAttribute(taskId, data) {
        return this.API.post(
            [this.DOMAIN, taskId, this.DOMAIN_ATTRIBUTES],
            data,
            [this.DOMAIN]
        );
    },

    updateAttribute(taskId, attributeId, data) {
        return this.API.put(
            [this.DOMAIN, taskId, this.DOMAIN_ATTRIBUTES, attributeId],
            data,
            [this.DOMAIN]
        );
    },

    deleteAttribute(taskId, attributeId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_ATTRIBUTES, attributeId]
        );
    },


    // Time entries

    getAllTimeEntries(taskId) {
        return this.API.get([this.DOMAIN, taskId, this.DOMAIN_TIMEENTRIES]);
    },

    getTimeEntry(taskId, entryId) {
        return this.API.get([this.DOMAIN, taskId, this.DOMAIN_TIMEENTRIES, entryId]);
    },

    createTimeEntry(taskId, hours) {
        return this.API.post([this.DOMAIN, taskId, this.DOMAIN_TIMEENTRIES], {hours: hours});
    },

    updateTimeEntry(taskId, entryId, hours) {
        return this.API.put(
            [this.DOMAIN, taskId, this.DOMAIN_TIMEENTRIES, entryId],
            {hours: hours},
            [this.DOMAIN, this.LANG_TIMEENTRIES]
            );
    },

    deleteTimeEntry(taskId, entryId) {
        return this.API.delete(
            [this.DOMAIN, taskId, this.DOMAIN_TIMEENTRIES, entryId],
            [this.DOMAIN, this.LANG_TIMEENTRIES]
        );
    }
};

const taskMovementDeferStrategy = (dataArray) => ({
    location_id: dataArray[0].location_id,
    quantity: dataArray.reduce((acc, cur) => acc + cur.quantity, 0)
});

export {TaskAPI, taskMovementDeferStrategy};

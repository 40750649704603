<template>
  <div>
    <template v-if="details">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container fluid>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') }}:</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') }}:</span>
                {{ details.priority | taskPriority }}
              </div>
              <div v-if="details.external_id">
                <span class="text-caption">{{ $t('tasks.external_id') }}:</span>
                {{ details.external_id }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') }}:</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') }}:</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <div v-if="details.description">
                <span class="text-caption">{{ $t('tasks.description') }}:</span>
                {{ details.description }}
              </div>
              <v-divider class="my-2" />
              <div v-if="details.external_order_states">
                <ExternalOrderStates :task-id="details.id" />
                <v-divider class="my-2" />
              </div>
              <div>
                <StockSubstock :substock="details.subordinate_stock" />
              </div>
              <div v-if="details.buyer">
                <div>
                  <ExternalOrderBuyerDetail
                    :order-id="details.id"
                    :buyer-id="details.buyer.id"
                  />
                </div>
              </div>
              <div v-if="details.carrier">
                <span class="text-caption">{{ $t('tasks.externalOrder.carrier') }}:</span>
                <router-link :to="'/carriers/' + details.carrier.id">
                  {{ details.carrier.name }}
                </router-link>
                <span v-if="details.carrier_service">
                  / {{ details.carrier_service.name }}
                </span>
              </div>
              <ExternalOrderParametersList :order="details" />
              <div>
                <span class="text-caption">{{ $t('tasks.externalOrder.externalFields.label') }}:</span>
                <ul>
                  <div
                    v-for="field of ['order_id', 'note', 'author_name', 'author_id', 'id']"
                    :key="field"
                  >
                    <li v-if="details['external_' + field]">
                      <span class="text-caption">{{
                        $t('tasks.externalOrder.externalFields.' + field)
                      }}</span>:
                      {{ details['external_' + field] }}
                    </li>
                  </div>
                  <li v-if="details.external_order_created_at">
                    <span class="text-caption">{{
                      $t('tasks.externalOrder.externalFields.order_created_at')
                    }}</span>:
                    <DateTimeWithTooltip :date-time="details.external_order_created_at" />
                  </li>
                </ul>
              </div>
              <div>
                <span class="text-caption">{{
                  $t('tasks.preparePackage.shipments.lastValidated')
                }}:</span>
                <template
                  v-if="details.last_validated !== null || details.last_validation_try !== null"
                >
                  <DateTimeWithTooltip
                    :date-time="details.last_validated || details.last_validation_try"
                  />
                </template>
                <template v-else>
                  {{ $t('tasks.preparePackage.shipments.never') }}
                </template>
              </div>
              <div v-if="details.stock_picking_task_ids.length > 0">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.stock_picking_task_ids')
                }}:</span>
                <span
                  v-for="(stockPickingId, index) of details.stock_picking_task_ids"
                  :key="stockPickingId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/stock-picking/' + stockPickingId">
                    #{{ stockPickingId }}
                  </router-link>
                </span>
              </div>
              <div v-if="details.prepare_shipment_package_task_ids.length > 0">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.prepare_shipment_package_task_ids')
                }}:</span>
                <span
                  v-for="(prepareShipmentId, index) of details.prepare_shipment_package_task_ids"
                  :key="prepareShipmentId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/prepare-package/' + prepareShipmentId + '?orderId=' + details.id">
                    #{{ prepareShipmentId }}
                  </router-link>
                </span>
              </div>
              <div v-if="details.shipment_ids.length > 0">
                <span class="text-caption">{{ $t('tasks.externalOrder.shipment_ids') }}:</span>
                <span
                  v-for="(shipmentId, index) of details.shipment_ids"
                  :key="shipmentId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/shipping?shipmentId=' + shipmentId">
                    #{{ shipmentId }}
                  </router-link>
                </span>
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
          <template v-if="isChief">
            <v-btn
              v-for="button in reservationButtons"
              v-show="button.condition"
              :key="button.title"
              type="submit"
              color="accent darken-2"
              class="mt-2"
              :loading="reservationLoading"
              @click="button.action"
            >
              <v-icon
                class="mr-2"
              >
                {{ button.icon }}
              </v-icon>
              {{ $t(button.title) }}
            </v-btn>
          </template>
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttributes
            :task-id="details.id"
            :task-type="type"
            :stock-id="details.subordinate_stock.stock_id"
            :sub-stock-id="details.subordinate_stock.id"
            :readonly="!isChief && (!assignedToCurrentUser || !isInProgress)"
          />
        </v-flex>
        <v-flex
          v-if="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          v-if="isChief"
          md4
          xs12
        >
          <ExternalOrderProcessingModePicker
            :task-info="taskInfo"
          />
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import ExternalOrderParametersList
        from "@/app/tasks/externalOrder/components/ExternalOrderParametersList.component";
    import ExternalOrderProcessingModePicker
        from "@/app/tasks/externalOrder/components/ExternalOrderProcessingModePicker";
    import ExternalOrderBuyerDetail from "@/app/tasks/externalOrder/components/ExternalOrderBuyerDetail.component.vue";
    import StockSubstock from "@/app/components/StockSubstock.component";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {taskTypes} from "@/enum/task_type";
    import ExternalOrderStates from "./ExternalOrderStates.component";
    import {TaskProductReservationAPI} from "@/api/TaskProductReservationAPI";
    import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {TaskState} from "@/enum/task_state";
    import {TaskAPI} from "@/api/TaskAPI";
    import {EventBus} from "@/service/EventBus";

    export default {
        name: "ExternalOrderAssignment",
        components: {
            ExternalOrderStates, TaskAttributes, StockSubstock, ExternalOrderBuyerDetail, ExternalOrderParametersList,
            DateTimeWithTooltip, EntityHistory, TaskAttachments, Notes, ExternalOrderProcessingModePicker
        },
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            items: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            type: taskTypes.EXTERNAL_ORDER,
            reservation: null,
            reservationLoading: false,
        }),
        createdOrActivated: function () {
            this.fetchReservation();
        },
        computed: {
            details: function () {
                return this.taskInfo.details;
            },
            isSatisfied: function () {
                return !this.items.some(item => item.quantity !== item.satisfied_quantity);
            },
            reservationButtons: function () {
                return [
                    {
                        condition: this.reservation === null
                            && this.taskInfo.details.processing_mode !== TaskExternalOrderProcessingMode.DO_NOT_PROCESS
                            && !this.isSatisfied,
                        icon: '$taskProductsReservation',
                        title: 'tasks.externalOrder.reservations.create',
                        action: this.createReservation,
                    },
                    {
                        condition: this.reservation !== null,
                        icon: '$deleteTableItem',
                        title: 'tasks.externalOrder.reservations.cancel',
                        action: this.cancelReservation,
                    },
                ];
            }
        },
        methods: {
            fetchReservation: function (reservationCreated = false) {
                this.reservationLoading = true;
                const filter = APIFilters.makeFilter([
                    {
                        [APIFilterOP.EQUALS]: {
                            'parent_task_id': this.taskInfo.taskId
                        }
                    },
                    {
                        [APIFilterOP.EQUALS]: {
                            type: taskTypes.PRODUCTS_RESERVATION
                        }
                    },
                    {
                        [APIFilterOP.NOT_IN]: {
                            state: [TaskState.CLOSED, TaskState.CANCELED]
                        }
                    }
                ]);
                let promise;
                if (reservationCreated) {
                    // new reservation is created asynchronously
                    promise = TaskAPI.getOneWithPolling(filter);
                } else {
                    promise = TaskAPI.getAll({filter});
                }
                promise.then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        this.reservation = response.data.items[0];
                    }
                    if (reservationCreated) {
                        EventBus.$emit('fetch-items', {
                            finallyFn: () => this.reservationLoading = false
                        });
                    }
                }).catch(err => {
                    if (typeof err === 'string' && err === 'error 423') {
                        this.snack('tasks.productsReservation.create.status.423');
                    } else {
                        this.snack(err);
                    }
                    if (reservationCreated) {
                        this.reservationLoading = false;
                    }
                }).finally(() => {
                    if (!reservationCreated) {
                        this.reservationLoading = false;
                    }
                });
            },
            createReservation: function () {
                this.reservationLoading = true;
                TaskProductReservationAPI.create({external_order_id: this.taskInfo.taskId})
                    .catch(this.snack)
                    .finally(() => {
                        this.fetchReservation(true);
                    });
            },
            cancelReservation: function () {
                if (!this.reservation) {
                    return;
                }
                this.reservationLoading = true;
                TaskProductReservationAPI.cancel(this.reservation.id)
                    .then(() => {
                        EventBus.$emit('fetch-items', {
                            finallyFn: () => {
                                this.reservation = null;
                                this.reservationLoading = false;
                            }
                        });
                    })
                    .catch(err => {
                        this.snack(err);
                        this.reservationLoading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>

<template>
  <div class="py-5">
    <Alert
      :display-text="$t('stocks.stockStatus.buyPrices.newLocation')"
      show-alert
      :actions="[{
        text: $t('base.reports.redirect'),
        to: '/exports'
      }]"
    />
    <StockBuyPricesComponent
      v-for="(type, index) of ['IN', 'CURR', 'OUT']"
      :key="type"
      :type="type"
      :class="index > 0 ? 'mt-4' : ''"
    />
  </div>
</template>

<script>
    import StockBuyPricesComponent from "@/app/overview/reports/components/StockBuyPrices.component";
    import Alert from "@/app/components/Alert.component.vue";

    export default {
        name: "StockBuyPricesBundleComponent",
        components: {Alert, StockBuyPricesComponent}
    };
</script>

<style scoped>

</style>

import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {CachePath} from "@/service/cache/CacheConfiguration";
import {merge} from "@/utils/object";

const TaskProductReservationAPI = {

    API: BaseAPI,
    DOMAIN: 'tasks/products-reservation',
    DOMAIN_ITEMS: 'items',
    DOMAIN_PRODUCTS: 'products',
    LANG: 'tasks.productsReservation',
    LANG_TASKS: 'tasks',

    get(taskId, forceFromAPI = false) {
        return APIHelper.getWithCache({
            collection: CachePath.taskDetails,
            key: taskId,
            requestParams: [[this.DOMAIN, taskId], this.LANG_TASKS]
        }, forceFromAPI);
    },

    getAllItems(taskId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS],
            this.LANG_TASKS
        );
    },

    getItem(taskId, itemId) {
        return this.API.get(
            [this.DOMAIN, taskId, this.DOMAIN_ITEMS, itemId],
            [this.LANG, this.DOMAIN_ITEMS]
        );
    },

    getAllByProduct(productId, ...params) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_PRODUCTS, productId],
            undefined,
            merge(...params),
        );
    },

    getAllPagesByProduct(productId, ...params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_PRODUCTS, productId], undefined, merge(...params));
    },

    create(data) {
        return this.API.post(this.DOMAIN, data, [this.LANG, 'create']);
    },

    cancel(reservationId) {
        return this.API.post(
            [this.DOMAIN, reservationId, 'cancel'],
            undefined,
            [this.LANG, 'cancel']
        );
    }
};

export {TaskProductReservationAPI};

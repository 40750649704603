import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.conflict)?_c('TaskConflict',{attrs:{"error":_vm.conflict}}):_vm._e(),(_vm.showUnprocessedItemsWarning)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('tasks.unprocessedItemsRemaining'))+" ")]):_vm._e(),_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"md8":"","xs12":""}},[(_vm.isWorker && !_vm.isChief && _vm.assignedToCurrentUser && !_vm.isClosed)?_c('TaskSimpleStateChangeCard',{attrs:{"api":_vm.api,"action":_vm.TaskAction.FINISH,"task-info":_vm.taskInfo},on:{"conflict":cnflct => _vm.conflict = cnflct}}):_vm._e(),_vm._t("approveOption",function(){return [(_vm.isWorker && _vm.isChief && _vm.isInProgress && _vm.assignedToCurrentUser)?_c('TaskSimpleStateChangeCard',{attrs:{"api":_vm.api,"finish-and-approve":true,"task-info":_vm.taskInfo},on:{"conflict":cnflct => _vm.conflict = cnflct}}):_vm._e(),(_vm.isChief && _vm.isWaitingForApproval)?_c('TaskSimpleStateChangeCard',{attrs:{"api":_vm.api,"action":_vm.TaskAction.APPROVE,"task-info":_vm.taskInfo},on:{"conflict":cnflct => _vm.conflict = cnflct}}):_vm._e()]})],2),(_vm.assignedToCurrentUser && !_vm.isWaitingForApproval && !_vm.isType(_vm.taskTypes.STOCK_TAKING) && !_vm.isClosed)?_c(VFlex,{attrs:{"md4":"","xs12":""}},[_c('TaskSimpleStateChangeCard',{attrs:{"api":_vm.api,"action":_vm.TaskAction.UNASSIGN,"task-info":_vm.taskInfo}})],1):_vm._e(),(_vm.isChief && (_vm.isCreated || _vm.isUnassigned || _vm.isWaitingForApproval)
        && !_vm.isType(_vm.taskTypes.LOCATION_TRANSFER)
        && (!_vm.isType(_vm.taskTypes.STOCK_PICKING_SET) || _vm.isWaitingForApproval)
        && (!_vm.isType(_vm.taskTypes.EXTERNAL_ORDER) || !_vm.isWaitingForApproval))?_c(VFlex,{attrs:{"md4":"","xs12":""}},[_c('TaskSimpleStateChangeCard',{attrs:{"api":_vm.api,"action":_vm.isWaitingForApproval ? _vm.TaskAction.REJECT : _vm.TaskAction.CANCEL,"task-info":_vm.taskInfo}})],1):_vm._e()],1),(_vm.showMovements)?_c(VCard,{staticClass:"ml-1 mt-1"},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('tasks.movementsOverview.title'))+" ")]),_c(VCardText,{staticClass:"mt-n10"},[(_vm.isType(_vm.taskTypes.SUBSTOCK_TRANSFER))?_c('div',{staticClass:"mt-8 text-h6 mb-n10"},[_vm._v(" "+_vm._s(_vm.$t('tasks.movementsOverview.sourceSubstock') + ': ')+" "+_vm._s(_vm.taskInfo.details.source_subordinate_stock.name)+" ")]):_vm._e(),_c('StockMovementsTable',{attrs:{"show-task-id":_vm.isType(_vm.taskTypes.STOCK_PICKING_SET),"show-task-type":false,"show-sub-stock":false,"api-filter":_vm.sourceApiFilter,"reload":_vm.reloadMovements}}),(_vm.isType(_vm.taskTypes.SUBSTOCK_TRANSFER))?_c('div',{staticClass:"text-h6 mb-n10"},[_vm._v(" "+_vm._s(_vm.$t('tasks.movementsOverview.destinationSubstock') + ': ')+" "+_vm._s(_vm.taskInfo.details.destination_subordinate_stock.name)+" ")]):_vm._e(),(_vm.isType(_vm.taskTypes.SUBSTOCK_TRANSFER))?_c('StockMovementsTable',{attrs:{"show-task-id":_vm.isType(_vm.taskTypes.STOCK_PICKING_SET),"show-task-type":false,"show-sub-stock":false,"api-filter":_vm.destinationApiFilter,"reload":_vm.reloadMovements}}):_vm._e()],1)],1):_vm._e(),(_vm.isType(_vm.taskTypes.STOCK_LOADING) && _vm.isChief)?_c('StockLoadingItemsPrices',{staticClass:"ml-1 mt-1",attrs:{"task-info":_vm.taskInfo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="resetFromLocationId"
    />
    <v-layout
      v-if="ready"
      wrap
    >
      <v-container fluid>
        <div
          v-if="isInProgress && assignedToCurrentUser"
          class="d-flex flex-row justify-space-between"
        >
          <v-autocomplete
            v-model="fromLocationId"
            clearable
            :items="locations"
            :item-text="locationLabel"
            item-value="id"
            :loading="loadingLocations"
            solo
            @clear-input="resetFromLocationId"
          />
          <v-icon class="mb-6 mx-3">
            $locationTransferMove
          </v-icon>
          <v-autocomplete
            v-model="toLocationId"
            :disabled="!fromLocationId"
            :error-messages="toLocationErrors"
            :items="locations"
            :item-text="locationLabel"
            item-value="id"
            :loading="loadingLocations"
            :solo="!!fromLocationId"
            :solo-inverted="!fromLocationId"
          />
        </div>
        <TaskConflict
          v-if="conflict"
          :error="conflict"
        />
        <ProductInstancesChips
          v-if="isInProgress && assignedToCurrentUser"
          :items="currentLocationItems"
          :loading="loadingLocationItems"
          lang-path="stocks.locations.items"
          :show-empty="!!fromLocationId"
        />
        <template v-if="items.length || isClosed">
          <v-divider
            v-if="isInProgress && assignedToCurrentUser"
            class="my-6"
          />
          <div class="subtitle-2 mb-2">
            {{ $t('tasks.locationTransfer.moved') }}:
          </div>
        </template>
        <v-alert
          v-if="isClosed && items.length === 0"
          class="ml-2"
          color="secondary"
          outlined
          type="warning"
        >
          {{ $t('tasks.locationTransfer.noneMoved') }}
        </v-alert>
        <v-card
          v-for="[key, /* TODO use group and list items */] of Object.entries(itemGroups)"
          :key="key"
          class="my-2"
        >
          <v-card-text>
            {{ LocationCache[key.split('-')[0]] | locationLabel }} ➜
            {{ LocationCache[key.split('-')[1]] | locationLabel }}
          </v-card-text>
        </v-card>
      </v-container>
    </v-layout>
    <v-dialog
      v-model="confirmDialog"
      width="450"
    >
      <v-card>
        <v-card-title>
          {{
            $t('tasks.locationTransfer.confirmTitle',
               [quantities.items, quantities.pieces, locationLabel(fromLocation), locationLabel(toLocation)])
          }}
        </v-card-title>
        <v-card-text>
          {{
            $t('tasks.locationTransfer.confirmWithReader', [toLocation ? toLocation.code : ''])
          }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="accent"
            @click="confirmMove"
          >
            {{ $t('tasks.locationTransfer.confirmButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {TaskLocationTransferAPI as API} from "@/api/TaskLocationTransferAPI";
    import {scannerModes} from "@/enum/scanner_mode";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import {CodeType} from "@/enum/code_type";
    import ProductInstancesChips from "@/app/products/instances/components/ProductInstancesChips.component";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {StockAPI} from "@/api/StockAPI";
    import {locationLabel} from "@/utils/string";
    import {has} from "@/utils/object";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {readerFeedback} from "@/utils/readerFeedback";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";

    export default {
        name: "LocationsTransferTransfer",
        components: {TaskConflict, ProductInstancesChips, TaskBarcodeReader},
        mixins: [TaskStateMixin, TaskAssignMixin, TaskFetchItemsMixin, ReactiveLocationCacheMixin, EventsListenerMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            fromLocationId: null,
            toLocationId: null,
            toLocationErrors: [],
            loadingLocations: false,
            locations: [],
            items: [],
            API: API,
            TaskItemsCardType: TaskItemsCardType,
            locationItems: {},
            locationLabel: locationLabel,
            loadingLocationItems: false,
            confirmDialog: false,
            conflict: null,
        }),
        computed: {
            events: function () {
                return {
                    'fetch-items': this.onFetchItems
                };
            },
            scannerMode: function () {
                if (this.fromLocationId !== null) {
                    return scannerModes.DESTINATION;
                }
                return scannerModes.IDLE;
            },
            fromLocation: function () {
                return this.locations.find(loc => loc.id === this.fromLocationId);
            },
            toLocation: function () {
                return this.locations.find(loc => loc.id === this.toLocationId);
            },
            currentLocationItems: function () {
                if (has(this.locationItems, this.fromLocationId)) {
                    return this.locationItems[this.fromLocationId];
                } else {
                    return [];
                }
            },
            quantities: function () {
                if (has(this.locationItems, this.fromLocationId)) {
                    return {
                        items: this.locationItems[this.fromLocationId].length,
                        pieces: this.locationItems[this.fromLocationId]
                            .map(item => item.quantity)
                            .reduce((acc, curr) => acc + curr, 0)
                    };
                } else {
                    return {
                        items: 0,
                        pieces: 0
                    };
                }
            },
            itemGroups: function () {
                const groups = {};
                this.items.forEach(item => {
                    item.source_and_dest_ids = item.source_location_id + '-' + item.destination_location_id;
                    if (!groups[item.source_and_dest_ids]) {
                        groups[item.source_and_dest_ids] = [];
                    }
                    groups[item.source_and_dest_ids].push(item);
                });
                Object.keys(groups).forEach(locationIds => {
                    locationIds.split('-').forEach(locationId => {
                        this.cacheLocation(Number.parseInt(locationId, 10));
                    });
                });
                return groups;
            }
        },
        watch: {
            fromLocationId: function (newValue) {
                this.toLocationId = null;
                if (newValue) {
                    this.fetchLocationItems(newValue);
                }
            },
            toLocationId: function (newValue) {
                this.toLocationErrors = [];
                this.conflict = null;
                if (newValue) {
                    if (newValue === this.fromLocationId) {
                        this.toLocationErrors.push(this.$t('tasks.locationTransfer.canNotUseSame'));
                        readerFeedback.error();
                    } else {
                        if (this.taskInfo.details.allow_filled_locations) {
                            this.confirmDialog = true;
                        } else {
                            this.fetchLocationItems(newValue)
                                .then(() => {
                                    if (this.locationItems[newValue].length > 0) {
                                        this.toLocationErrors.push(this.$t('tasks.locationTransfer.canNotUseNotEmpty'));
                                        readerFeedback.error();
                                    } else {
                                        this.confirmDialog = true;
                                    }
                                });
                        }
                    }
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.fetchItems({initial: lifeCycleHook === this.LifeCycleHook.CREATED})
                .then(() => {
                    this.ready = true;
                }).catch(this.snack);
            this.fetchLocations();
        },
        activated: function () {
            this.toLocationId = null;
        },
        methods: {
            fetchLocations: function () {
                this.loadingLocations = true;
                StockAPI.getAllLocationsAllPages(this.taskInfo.details.stock.id)
                    .then(response => {
                        this.locations = response.data.items;
                    })
                    .catch(this.snack)
                    .finally(() => {
                        this.loadingLocations = false;
                    });
            },
            fetchLocationItems: function (locationId, forceFetch = false) {
                if (!has(this.locationItems, locationId) || forceFetch) {
                    this.$set(this.locationItems, locationId, []);
                }
                if (this.locationItems[locationId].length === 0) {
                    this.loadingLocationItems = true;
                    return StockStatusAPI.getInStockOnLocation(this.taskInfo.details.stock.id, locationId)
                        .then(response => {
                            this.$set(this.locationItems, locationId, response.data
                                .map(item => ({
                                    instance: item.product_instance,
                                    quantity: item.summary_quantity
                                })));
                        }).catch(this.snack)
                        .finally(() => {
                            this.loadingLocationItems = false;
                        });
                } else {
                    return Promise.resolve();
                }
            },
            handleAcceptBarcode: function (barcodeInfo) {
                if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                    this.readingFail('tasks.locationTransfer.scanLocation');
                } else if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    if (this.fromLocationId === null) {
                        this.fromLocationId = barcodeInfo.object_id;
                    } else {
                        this.toLocationId = barcodeInfo.object_id;
                    }
                    this.readingDone();
                } else {
                    this.readingFail('base.api.barcodes.unknown');
                }
            },
            handleRejectBarcode: function () {
                this.readingFail();
            },
            resetFromLocationId: function () {
                this.fromLocationId = null;
            },
            confirmMove: function () {
                this.confirmDialog = false;
                API.moveFromSourceToDestination(this.taskInfo.taskId, this.fromLocationId, this.toLocationId)
                    .then(() => {
                        // re-fetch items at source and destination locations
                        this.fetchLocationItems(this.fromLocationId, true);
                        this.fetchLocationItems(this.toLocationId, true);
                        this.resetFromLocationId();
                        this.toLocationId = null;
                        this.readingDone(); // No need to wait for the items as they are independent
                        this.conflict = null;
                        this.fetchItems();
                    })
                    .catch(err => {
                        this.conflict = err.response.data;
                        this.readingFail();
                    });
            }
        }
    };
</script>

<style lang="sass">
.LocationTransferTransfer--hideRollDown .v-input__append-inner
  display: none
</style>

import {Status} from "@/enum/import&export_state";
import {Domains} from "@/enum/domains";

const configNotFound = "Не вдалося знайти вибрану шаблону";
const exportNotFound = "Не вдалося знайти вибраний імпорт";
export default {
    title: "Експорт",
    exports: "Експорти",
    link: "Експортувати",
    status: "Статус",
    domain: "Домен",
    download: "Завантажити",
    errorMessage: "Помилка",
    processed_at: "Оброблено о",
    created_by: "Створено користувачем",
    created_at: "Створено о",
    data_type: "Тип даних",
    type: "Тип файлу",
    export_create: "Створити експорт",
    error: "Помилка",
    continue: "Продовжити експорт",
    view: "Переглянути експорт",
    created: "Експорт створено",
    process: "Обробити імпорт",
    processed: "Експорт у черзі на обробку",
    deleted: "Експорт видалено",
    showStatistics: "Розрахувати статистику експорту",

    configuration: {
        title: "Шаблон експорту",
        templates: "Шаблони експорту",
        file: "Вихідний файл",
        create: "Створити шаблон експорту",
        created: "Шаблон експорту створено",
        config: "Конфігурація шаблону",
        config_subtitle: "Якщо ви хочете, ви можете налаштувати свій шаблон тут",
        updated: "Шаблон оновлено",
        create_copy: "Створити копію шаблону експорту",
        view: "Переглянути шаблон",
        save: "Зберегти шаблон",
        copy: "Скопіювати шаблон",
        name: "Назва шаблону",
        domain: "Домен цільових даних",
        deleted: "Шаблон видалено",
        parameters: "Параметри шаблону експорту"
    },
    info: {
        title: "Інформація про експорт",
        hint: "Інформація про створений експорт обчислюється, цей процес може займати кілька хвилин.",
        include: "Експорт буде включати: ",
        error: "З цією конфігурацією експорту виникне помилка з цим повідомленням: ",
        exported: "Рядків для експорту",
        config_hint: "Тут ви можете налаштувати свій шаблон. Ви можете перемикати конкретні стовпці, клікнувши на їх заголовок. Приховані стовпці не включатимуться у експорт",
        parameters_hint: "Параметри експорту використовуються для фільтрації експортованих даних. Будь ласка, заповніть всі показані параметри",
        statistics_warn: "Поки статистика не розрахована, експорт не може завершитися. Ви все ще бажаєте продовжити?"
    },
    create: {
        title: "Створити експорт",
        dateHint: "Використовуйте формат РРРР-ММ-ДД для дат"
    },
    parameters: {
        title: "Параметри експорту",
        warning: "Всі параметри експорту повинні бути заповнені"
    },
    state: {
        [Status.CREATED]: 'Створено',
        [Status.UPLOADED]: 'Завантажено',
        [Status.QUEUED]: 'В черзі',
        [Status.PROCESSING]: 'Вони обробляються',
        [Status.FAILED]: 'Не вдалося',
        [Status.VALIDATION_FAILED]: 'Неправильний шаблон',
        [Status.FINISHED]: 'Виконано',
        [Status.DRY_RUN_QUEUED]: 'Очікується статистика',
        [Status.DRY_RUN_PROCESSING]: 'Виконується розрахунок статистики',
        [Status.DRY_RUN_FAILED]: 'Помилка під час розрахунку статистики',
        [Status.DRY_RUN_VALIDATION_FAILED]: 'Неправильний шаблон для статистики',
        [Status.DRY_RUN_FINISHED]: 'Статистика розрахована',
    },
    domains: {
        [Domains.BUYER]: 'Покупці',
        [Domains.PRODUCT]: 'Товари',
        [Domains.BUY_PRICE]: "Ціна закупки - стан складу",
        [Domains.BUY_PRICE_FOR_STOCK_PICKING]: "Ціна закупки - відвантажено",
        [Domains.BUY_PRICE_FOR_STOCK_LOADING]: "Ціна закупки - запасено",
        [Domains.BULK_EXTERNAL_ORDER]: "Замовлення",
        [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: 'Щоденний звіт про зайнятість локації',
        [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: 'Щоденний звіт про заповнюваність локацій за типами',
        [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: 'Щоденний звіт про зайнятість локації з рухом',
        [Domains.INTERVAL_MOVEMENTS_REPORT]: 'Рухи за інтервалами',
        [Domains.SHARED_LOCATIONS_REPORT]: 'Спільні локації',
        [Domains.PRODUCT_AT_STOCK]: 'Акції товар',
        [Domains.TASK_ATTRIBUTES]: 'Атрибути завдання'
    },

    errors: {
        'export': {
            status: {
                404: exportNotFound,
            }
        },
        exportCreate: {
            status: {
                409: 'Не вдалося створити експорт'
            }
        },
        exportDelete: {
            status: {
                404: exportNotFound,
                409: "Не вдалося видалити експорт"
            }
        },
        exportUpdate: {
            status: {
                404: exportNotFound,
                409: 'Не вдалося оновити експорт'
            }
        },
        exportProcess: {
            status: {
                404: exportNotFound,
                409: "Не вдалося обробити експорт"
            }
        },
        config: {
            status: {
                404: configNotFound
            }
        },
        configCreate: {
            status: {
                404: configNotFound
            }
        }
    }
};

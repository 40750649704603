import {StockLocationLockType} from "@/enum/stock_location_lock_type";
import {PreparePackageMode} from "@/enum/prepare_package_mode";

const stockOrSubStockOrLocationNotFound = "Warehouse, sub-warehouse or location does not exist!";

export default {
    title: "Warehouses",
    link: "Warehouses",

    name: "Warehouse name",
    id: "Warehouse ID",
    description: "Description",
    is_small: "Small warehouse",
    address: "Address",

    chief: "Manager",
    chief_id: "Manager",

    locations: {
        label: "Warehouse locations",
        name: "Location name",
        code: "Code",
        availability: "Availability",
        availability_hint: "How well this locations is available - higher is better.",
        setAvailability: "Set availability",
        availabilityBatch: {
            failed: "Failed to set availability of the locations!",
            done: "Availability set."
        },
        is_expedition: "Packing",
        expedition: "Packing",
        is_mobile: "Mobile",
        mobile: "Mobile",
        nonMobile: "Non-mobile",
        codehint: "Will auto-generate if left empty.",
        none: "<no location>",
        at: "at location",
        is_allowed: "Allowed for this sub-warehouse",
        stockStatus: "Items on location",
        searchHint: "Searches through location name and code",

        printBarcode: "Print barcode",
        printBarcodeMany: "Print barcodes",
        printBarcodeHowMuch: "How many '{0}' barcodes to print?",
        printBarcodesHowMuch: "How many barcodes to print?",
        printDone: "Printing barcode '{0}'...",

        items: {
            productsAtSubstock: "Items at the selected location",
            noItems: "No items at the selected location!"
        },

        locks: {
            strict_mode: "Strict lock",
            strictHint: "Any item under a strict lock cannot be placed on any location that does not specifically permit it.",
            note: "Note",
            lockTypes: {
                title: "This location is restricted to {0}",

                [StockLocationLockType.MANUFACTURER]: "Manufacturer",
                [StockLocationLockType.PRODUCT_TYPE]: "Stock item type",
                [StockLocationLockType.PRODUCT]: "Stock item",
                [StockLocationLockType.INSTANCE]: "Concrete stock item",
            },
            lockItems: {
                title: "Restrict location to",

                [StockLocationLockType.MANUFACTURER]: "Manufacturer",
                [StockLocationLockType.PRODUCT_TYPE]: "Stock item type",
                [StockLocationLockType.PRODUCT]: "Stock item",
                [StockLocationLockType.INSTANCE]: "Concrete stock item",
            },

            newType: "Type of new lock",
            removeType: "Lock on {0}",

            removeLock: "Remove lock",
            unableToRemoveLastLock: "You cannot remove the last lock!",
            lockAlreadySelected: "Lock is already in use!",
            allTypesExist: "This location already has all possible lock types!",

            create: {
                done: "Lock on {0} created.",
                titleSimple: "Add lock"
            },
            update: {
                done: "Lock on {0} updated."
            },
            delete: {
                done: "Lock on {0} deleted."
            },

            status: {
                404: "Warehouse, location or lock type does not exist!",
                409: "Invalid lock type!"
            }
        },

        show: {
            titleSimple: "Location",
            title: "Location '{0}' in '{1}'",
            label: "Items on location"
        },
        create: {
            titleSimple: "New location",
            title: "New location in {0}",
            label: "Create new location",
            done: "Location '{0}' created."
        },
        update: {
            titleSimple: "Update location",
            title: "Update location '{0}' in {1}",
            done: "Location '{0}' updated."
        },
        delete: {
            done: "Location '{0}' deleted.",
            failed: "Unable to delete '{0}'. Please try again later.",
            status: {
                404: "Warehouse or location does not exist!",
                409: "Unable to delete location due to references to other objects!"
            }
        },

        status: {
            400: "Wrong date format!",
            404: "Warehouse or location does not exist!",
            409: "Barcode is already in use!"
        }
    },

    substocks: {
        label: "Sub-warehouses",
        name: "Sub-warehouse name",
        id: "Sub-warehouse ID",
        stock_id: 'Warehouse name',
        description: "Description",
        noSubstocks: "No sub-warehouses",
        owner: {
            name: "Owner"
        },
        owner_id: "Owner",
        has_permission: "Has permission",
        prepare_package_mode: "Packaging mode",
        order_with_reservation: "Reserve ordered items",
        auto_merge_stock_pickings: "Auto-merge similar item pickings",
        auto_merge_stock_pickings_hint: "Has no effect on item pickings created from external orders.",
        allow_multiple_shipment_label_prints: "Allow to print label for an order multiple times",
        allow_multiple_shipment_label_prints_hint: "Chief can always print multiple labels.",
        mark_as_delivered_after_packing: "Automatically deliver personal collection after packing",
        mark_as_delivered_after_packing_hint: "Shipments of orders of the \"personal collection\" type will be marked as delivered as soon as they are packed.",

        items: {
            productsAtSubstock: "Items in the selected sub-warehouse",
            noItems: "No items in the selected sub-warehouse!"
        },

        show: {
            titleSimple: "Sub-warehouse",
            title: "'{0}' sub-warehouse in '{1}'"
        },
        create: {
            titleSimple: "New sub-warehouse",
            title: "New sub-warehouse in {0}",
            label: "Create new sub-warehouse",
            done: "Sub-warehouse '{0}' created.",
            addedPermission: "'{0}' now has access to '{1}'. It is recommended to set up access for other users as well."
        },
        update: {
            titleSimple: "Update sub-warehouse",
            title: "Update sub-warehouse '{0}' in '{1}'",
            done: "Sub-warehouse '{0}' updated."
        },
        delete: {
            done: "Sub-warehouse '{0}' deleted.",
            failed: "Unable to delete '{0}'. Please try again later.",
            status: {
                404: "Warehouse or sub-warehouse does not exist!",
                409: "Unable to delete sub-warehouse due to references to other objects!"
            }
        },

        status: {
            404: "Warehouse or sub-warehouse does not exist!",
            409: "Unable to delete sub-warehouse due to references to other objects!"
        },

        allowedLocations: {
            status: {
                404: "Warehouse, sub-warehouse or product does not exist!"
            }
        },

        allowedInstances: {
            status: {
                404: stockOrSubStockOrLocationNotFound
            }
        },

        whitelist: {
            list: "Allowed locations for '{0}'",
            available: "Available locations for '{0}'",
            auto: "Automatically allowed",

            create: {
                title: "Add to sub-warehouse",
                done: "'{0}' location added",
                failed: "Failed to add '{0}'"
            },
            delete: {
                title: "Remove from sub-warehouse",
                done: "'{0}' removed from sub-warehouse",
                failed: "Failed to remove '{0}' from sub-warehouse",
                status: {
                    404: stockOrSubStockOrLocationNotFound,
                    409: "Cannot remove location from the sub-warehouse due to references to products!"
                }
            },
            batch: {
                true: "Allow filtered",
                false: "Remove allowance from filtered",
                checked: {
                    true: "Add to whitelist",
                    false: "Remove from whitelist",
                },
                done: {
                    true: "Locations allowed",
                    false: "Allowance removed",
                },
                failed: "Change of allowance has failed!"
            },
            status: {
                404: "Warehouse or sub-warehouse does not exist!",
                409: "Location is already part of this sub-warehouse!"
            }
        },

        prepare_package_mode_items: {
            [PreparePackageMode.EAN_READ_EACH]: "Require to scan each item's EAN",
            [PreparePackageMode.SELECT_EACH]: "Allow selection of each item without scanning",
            [PreparePackageMode.SELECT_FIRST_AUTO_PACK_REST]: "Allow selection of the first item without scanning, then pack the rest of the order automatically"
        },

        task_settings: {
            label: "Task configuration",
            autoApprove: "Automatic task approval on task completion",
            autoPrintReport: "Automatic report printing on task completion",
            sortTaskItemsByLoaded: 'Sort locations in tasks from oldest',
            updateDone: "Configuration updated",
            status: {
                404: "Sub-warehouse does not exist!"
            }
        },

        task_attributes: {
            label: "Task attributes configuration",
            forSubstock: "Allowed attributes for each task type in this sub-warehouse",
            forStock: "Allowed attributes for each task type in the whole stock",
            forStockHint: "For some task types you do not need to enter sub-warehouse, hence you configure attributes for the whole stock.",
            updateDone: "Configuration updated",
        },

        order_processing: {
            label: "Configuration of automatic merging of item picking tasks",
            runNow: "Run merging process now",
            enable_automatic_triggering: "Enable automatic merging",
            max_automatic_sets: "Maximum concurrent groups",
            min_orders_per_automatic_set: "Minimum of item picking tasks in one item picking group",
            max_orders_per_automatic_set: "Maximum of item picking tasks in one item picking group",
            strategies: "Merging strategies",
            strategyPriority: "Priorities",
            strategy: {
                SAME_SINGLE_PRODUCT: "Matching single-item orders",
                SINGLE_PRODUCT: "Single-item orders",
                IDENTICAL_ORDERS: "Identical orders",
                FROM_OLDEST: "From oldest"
            },
            updateDone: "Configuration updated",
            updateDoneWithDisabling: "Configuration updated and Automatic merging disabled",
            activationDone: "Automatic merge activated",
            deactivationDone: "Automatic merging disabled",
            run: {
                status: {
                    204: "Task has been put into processing queue",
                    404: "Warehouse or sub-warehouse does not exist!"
                }
            },
            status: {
                404: "Warehouse or sub-warehouse does not exist!",
                409: "Invalid order processing configuration!",
            },
            active: "Automatic merging is activated",
            deactivated: "Automatic merging is disabled",
            tooltipActivationButtonDeactivated: "At least one strategy must be selected for activation to occur and all values in the configuration below must be positive integers. Everything must be saved.",
            tooltipActivationButtonCanNotBeChanged: "Before switching, it is necessary to save the changes with the button below.",
        },
        buyer_types: {
            label: "Assigned buyer types",
        },
    },

    owners: {
        name: "Name",
        ico: "ID",
        dic: "VAT ID",
        phone: "Phone",
        email: "E-mail",
        website: "Web",
        billing_address: "Billing address",
        bank_account: "Bank account",

        unableToLoad: "Unable to load owner. Please try again later.",

        link: "Sub-warehouse owners",

        list: {
            title: "Sub-warehouse owners"
        },
        show: {
            title: "Sub-warehouse owner"
        },
        create: {
            label: "Create new owner",
            titleSimple: "New sub-warehouse owner",
            title: "New sub-warehouse '{0}' owner",
            done: "Owner '{0}' created."
        },
        update: {
            titleSimple: "Update owner",
            title: "Update owner '{0}'",
            done: "Owner '{0}' updated."
        },
        delete: {
            done: "'{0}' deleted!",
            failed: "Unable to delete '{0}'. Please try again later.",
            status: {
                404: "Sub-warehouse owner does not exist!",
                409: "Cannot delete sub-warehouse owner due to references to other objects!"
            }
        },

        status: {
            404: "Owner does not exist!",
            409: "ID is already in use!"
        }
    },

    stockChoose: {
        form: {
            stockId: "Select warehouse",
            subStockId: "Select sub-warehouse",
            locationId: "Only at location ...",
            productId: "Specific product ...",
            instanceId: "Specific instance ...",
        }
    },

    stockStatus: {
        link: "Stock overview",
        title: "Stock overview",
        allSubstocks: "<all sub-warehouses>",
        allLocations: "<all locations in stock>",
        allLocationsIn: "<all locations in '{0}'>",
        allProducts: "<all items>",
        optional: "Optional",
        table: {
            stock: {
                id: "Stock",
                choose: "Select warehouse"
            },
            product_instance: {
                id: "Product name",
                choose: "Select product"
            },
            stock_location: {
                id: "Location"
            },
            quantity: "Quantity",
            last_change: "Last change",
            loaded_at: "Oldest item"
        },
        showItem: "View product",
        showLocation: "View location",

        locations: {
            link: "Location report",
            title: "Location daily usage report",
            generating: "Generating report ...",
            form: {
                stockId: "Warehouse",
                subStockId: "Sub-warehouse",
                dateFrom: "from",
                dateTo: "to",
            },
            table: {
                location_id: "Location ID",
                location_name: "Location name",
                days_used: "Days used"
            }
        },

        buyPrices: {
            name: "Export buy prices",
            title: {
                IN: "Export buy prices - stocked",
                CURR: "Export buy prices - stock level",
                OUT: "Export buy prices - picked"
            },
            exportTitle: "Purchase prices - stock level",
            newLocation: "These reports can now be found in the 'Export' tab.",

            stockId: "Warehouse",
            subStockId: "Sub-warehouse",
            from_date: "from",
            to_date: "to",
            date_from: "from",
            date_to: "to",

            status: {
                409: "Invalid date"
            }
        }
    },

    stockMovements: {
        link: "Stock movement",
        title: "Stock movement overview",
        table: {
            type: "Movement type",
            product_instance: {
                id: "Product"
            },
            task: {
                id: "Task",
                type: "Task type",
            },
            substock: {
                id: 'Sub-warehouse',
            },
            location: {
                id: 'Location',
                type: 'Location type',
                STOCK: 'Warehouse',
                USER: 'Storekeeper'
            },
            movement: {
                PUT: 'IN',
                PICK: 'OUT'
            },
            amount: "Amount",
            created_at: "Date",
            created_by: "Storekeeper",
        },
    },

    list: {
        unableToLoad: "Unable to load warehouse list. Please try again later."
    },
    show: {
        titleSimple: "Warehouse details",
        title: "{0}"
    },
    create: {
        title: "New warehouse",
        done: "Warehouse '{0}' created."
    },
    update: {
        titleSimple: "Update warehouse",
        title: "Update {0}",
        done: "Warehouse '{0}' updated."
    },
    delete: {
        done: "Warehouse '{0}' deleted.",
        failed: "Unable to delete '{0}'. Please try again later.",
    },

    status: {
        400: "Wrong date format!",
        404: "Warehouse does not exist!",
        409: "Unable to delete warehouse due to references to other objects!",
        423: "The report is not ready yet, please try again later."
    }
};

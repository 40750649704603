import {Status} from "@/enum/import&export_state";
import {Domains} from "@/enum/domains";

const configNotFound = "Nem található kiválasztott sablon";
const exportNotFound = "Nem található kiválasztott importálás";
export default {
    title: "Exportálás",
    exports: "Exportok",
    link: "Exportálás",
    status: "Állapot",
    domain: "Tartomány",
    download: "Letöltés",
    errorMessage: "Hibaüzenet",
    processed_at: "Feldolgozva",
    created_by: "Készítette",
    created_at: "Létrehozva",
    data_type: "Adattípus",
    type: "Fájltípus",
    export_create: "Exportálás létrehozása",
    error: "Hiba",
    continue: "Exportálás folytatása",
    view: "Exportálás megtekintése",
    created: "Exportálás létrehozva",
    process: "Importálás feldolgozása",
    processed: "Exportálás sorban áll a feldolgozásra",
    deleted: "Exportálás törölve",
    showStatistics: "Exportálási statisztikák számítása",

    configuration: {
        title: "Exportálási sablon",
        templates: "Exportálási sablonok",
        file: "Forrásfájl",
        create: "Exportálási sablon létrehozása",
        created: "Exportálási sablon létrehozva",
        config: "Sablon konfiguráció",
        config_subtitle: "Ha szeretné, itt konfigurálhatja a sablonját",
        updated: "Sablon frissítve",
        create_copy: "Exportálási sablon másolat létrehozása",
        view: "Sablon megtekintése",
        save: "Sablon mentése",
        copy: "Sablon másolása",
        name: "Sablon neve",
        domain: "Adatcél tartománya",
        deleted: "Sablon törölve",
        parameters: "Exportálási sablon paraméterek"

    },
    info: {
        title: "Exportálási információ",
        hint: "A létrehozott exportálásról szóló információk számítása folyamatban van, ez a művelet néhány percet is igénybe vehet.",
        include: "Az exportálás tartalmazni fogja: ",
        error: "Ezzel a konfigurációval történő exportálás hibához vezet ezzel az üzenettel:",
        exported: "Sorok exportálása",
        config_hint: "Itt konfigurálhatja a sablonját. A fejlécekre kattintva egyes oszlopokat ki- vagy bekapcsolhat. A rejtett oszlopok nem szerepelnek az exportálásban",
        parameters_hint: "Az exportálási paramétereket arra használják, hogy szűrjék az exportált adatokat. Kérjük, töltse ki az összes megjelenített paramétert",
        statistics_warn: "Amíg a statisztikák nincsenek kiszámolva, az exportálás nem fejeződhet be. Még mindig folytatni kívánja?"

    },
    create: {
        title: "Exportálás létrehozása",
        dateHint: "Dátumokhoz használja az ÉÉÉÉ-HH-NN formátumot"
    },
    parameters: {
        title: "Exportálási paraméterek",
        warning: "Minden exportálási paramétert ki kell tölteni"
    },

    state: {
        [Status.CREATED]: 'Létrehozva',
        [Status.UPLOADED]: 'Feltöltve',
        [Status.QUEUED]: 'Sorban áll',
        [Status.PROCESSING]: 'Feldolgozás alatt',
        [Status.FAILED]: 'Sikertelen',
        [Status.VALIDATION_FAILED]: 'Érvénytelen sablon',
        [Status.FINISHED]: 'Befejezve',
        [Status.DRY_RUN_QUEUED]: 'Várakozás statisztikákra',
        [Status.DRY_RUN_PROCESSING]: 'Statisztikák számítása folyamatban',
        [Status.DRY_RUN_FAILED]: 'Hiba a statisztikák számítása közben',
        [Status.DRY_RUN_VALIDATION_FAILED]: 'Érvénytelen sablon a statisztikákhoz',
        [Status.DRY_RUN_FINISHED]: 'Statisztikák kiszámolva',
    },

    domains: {
        [Domains.BUYER]: 'Vásárlók',
        [Domains.PRODUCT]: 'Termékek',
        [Domains.BUY_PRICE]: 'Vásárlási ár - készlet állapota',
        [Domains.BUY_PRICE_FOR_STOCK_PICKING]: 'Vásárlási ár - felvétel',
        [Domains.BUY_PRICE_FOR_STOCK_LOADING]: 'Vásárlási ár - bevételezés',
        [Domains.BULK_EXTERNAL_ORDER]: 'Külső megrendelés',
        [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: 'Napi helyfoglalási jelentés',
        [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: 'Napi helyfoglalási jelentés típusonként',
        [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: 'Napi helyfoglalási jelentés mozgással',
        [Domains.INTERVAL_MOVEMENTS_REPORT]: 'Mozgások intervallumonként',
        [Domains.SHARED_LOCATIONS_REPORT]: 'Közös helyszínek',
        [Domains.PRODUCT_AT_STOCK]: 'Termékkészlet',
    [Domains.TASK_ATTRIBUTES]: 'Feladat attribútumok'
    },

    errors: {
        'export': {
            status: {
                404: exportNotFound,
            }
        },
        exportCreate: {
            status: {
                409: 'Nem sikerült exportálást létrehozni'
            }
        },
        exportDelete: {
            status: {
                404: exportNotFound,
                409: "Nem sikerült az exportálást törölni"
            }
        },
        exportUpdate: {
            status: {
                404: exportNotFound,
                409: 'Nem sikerült az exportálást frissíteni'
            }
        },
        exportProcess: {
            status: {
                404: exportNotFound,
                409: "Nem sikerült az exportálást feldolgozni"
            }
        },
        config: {
            status: {
                404: configNotFound
            }
        },
        configCreate: {
            status: {
                404: configNotFound,
                409: "Nem sikerült sablon létrehozása"
            }
        },
        configUpdate: {
            status: {
                404: configNotFound,
                409: "Nem sikerült sablon frissítése"
            }
        },
        configDelete: {
            status: {
                404: configNotFound,
                409: "Nem sikerült sablon törlése"
            }
        },

    }
};

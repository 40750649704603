import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
import {taskTypes} from "@/enum/task_type";
import {EventBus} from "@/service/EventBus";

/**
 * Requires:
 *  - this.itemsInInventory
 *  - this.activeLocationId
 *  - this.taskInfo.taskId
 *  - this.showMovingAllAlert
 *  - this.API
 */
const MoveAllMixin = {
    mixins: [TaskTypeMixin],
    methods: {
        moveAllFromInventory: function () {
            this.sendUpdatedQuantity().then(() => {
                const promises = [];
                this.itemsInInventory.forEach(item => {
                    if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                        || (!item.allowedLocationIds || item.allowedLocationIds.includes(this.activeLocationId))) {
                        promises.push(new Promise((resolve, reject) => {
                            this.API.putToDestination(this.taskInfo.taskId, item.id, this.activeLocationId, item.quantity_in_user_inventory)
                                .then(resolve)
                                .catch(reject);
                        }));
                    }
                });
                if (promises.length > 0) {
                    this.showMovingAllAlert = true;
                    Promise.all(promises)
                        .then(() => {
                            this.conflict = null;
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 409) {
                                this.conflict = err.response.data;
                            } else {
                                this.snack(err);
                            }
                        }).finally(() => {
                        // fetch all items after success or failure, only some items might have been moved
                        // show alert for at least 2 seconds
                        const delayPromise = new Promise(resolve => setTimeout(resolve, 2000));
                        EventBus.$emit('fetch-items', {
                            finallyFn: () => {
                                // after items are fetched either hide alert or wait for delay to finish
                                delayPromise.then(() => {
                                    this.showMovingAllAlert = false;
                                });
                            }
                        });
                    });
                }
            });
        }
    }
};

export {MoveAllMixin};
